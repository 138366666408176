<template>
  <div class="wrapper">
    <div slot="header" class="header">
      <div class="dict-title">
        字典名称：
        <el-tag type="success">{{ $route.query.dictName }}</el-tag>
      </div>
      <div>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="goAdd">添加</el-button>
        <el-button type="info" size="small" icon="el-icon-back" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <el-table :data="list">
      <el-table-column prop="name" label="字典值名称" min-width="120"> </el-table-column>
      <el-table-column prop="key" label="key值" min-width="120"> </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="120"> </el-table-column>
      <el-table-column prop="id" label="操作" minwidth="200" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="page" :limit.sync="pageSize" @pagination="getList" />

    <!-- 新增 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_dict_update" width="width">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="值名称" prop="name" :rules="rules">
          <el-input style="width: 200px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="值标识" prop="key" :rules="rules">
          <el-input style="width: 200px" v-model="formData.key" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input style="width: 200px" v-model="formData.sort" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_dict_update = false">取 消</el-button>
        <el-button type="primary" @click="submitDict">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDictValuesListAPI, addDictValueAPI, editDictValueAPI, delDictValueAPI, getDictValueDetailAPI } from './api'

export default {
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_dict_update: false,
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      formData: {
        name: '',
        key: '',
        parentKey: this.$route.query.dictCode,
        sort: 0
      }
    }
  },

  mounted() {
    this.getList()
  },
  methods: {
    goAdd() {
      this.show_dict_update = true
      this.formData = {
        name: '',
        key: '',
        parentKey: this.$route.query.dictCode,
        sort: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // 编辑字典
    async handleEdit({ id }) {
      this.show_dict_update = true
      this.$nextTick(async () => {
        this.formData = await getDictValueDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    // 删除字典
    handleDel({ id }) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDictValueAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 提交字典
    submitDict() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editDictValueAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_dict_update = false
            })
          } else {
            addDictValueAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_dict_update = false
            })
          }
        }
      })
    },

    async getList() {
      let res = await getDictValuesListAPI(this.$route.query.dictCode)
      this.list = res.records || []
      this.total = res.totalRow || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
